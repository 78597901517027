import React from "react";
import { Link } from "react-router-dom";
import API from "./../api";

class Classes extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: false,
            error: false
        };
    }

    render() {
        return (
            <section className="classes-small">
                <div className="effect">
                    <div className="container py-5">

                        { this.state.loading && (
                            <div className="row">
                                <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                            </div>
                        )}

                        { this.state.error && (
                            <div className="row pb-3">
                                <div className="alert alert-danger m-auto" role="alert">
                                    {this.state.error}
                                </div>
                            </div>
                        )}

                        {this.state.data && !this.state.loading && !this.state.error && (
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-9 text-center text-lg-left">
                                    <h1>{this.state.data.title}</h1>
                                    <p>{this.state.data.subtitle}</p>
                                </div>
                                <div className="col-lg-3 text-center">
                                    <Link to="/classes" className="btn btn-outline-primary">{this.state.data.button_text}</Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("classBlock")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ data: res.data });
                }
            })
            .catch(err => {
                if(this._isMounted) {
                    this.setState({ error: err.message });
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default Classes;