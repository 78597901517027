import React from "react";
import parse from "html-react-parser";

class AboutFirst extends React.Component {
    render() {
        return (
            <section className="about first">
                <div className="container py-3 py-lg-5 d-flex">
                    <div className="d-none d-lg-block">
                        <div className="line-wrap"></div>
                    </div>
                    <div className="px-lg-5">

                        { this.props.loading && (
                            <div className="row py-5">
                                <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                            </div>
                        )}

                        { this.props.error && (
                            <div className="row py-5">
                                <div className="alert alert-danger m-auto" role="alert">
                                    {this.props.error}
                                </div>
                            </div>
                        )}

                        { !this.props.loading && !this.props.error && (
                            <div>
                                <div className="row">
                                    <div className="col pb-3">
                                        <h1>{this.props.data.title}</h1>
                                        <h3>{this.props.data.subtitle}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <img src={this.props.data.image_url} alt={"about1"} className="about-image right"/>
                                        {parse(this.props.data.content)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutFirst;