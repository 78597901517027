import React from "react";
import API from "./../../api";

class ContactMap extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            src: null,
            error: false,
            loading: true,
            title: "contact-map",
            width: "100%",
            height: "100%",
            frameborder: 0,
            style: {
                border: 0
            },
            allowfullscreen: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        API.get("mapUrl")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ src: res.data.url });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    this.setState({ error: error.message });
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <section className="contact-map" id="contact-map">

                { this.state.loading && (
                    <div className="row py-5">
                        <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                    </div>
                )}

                { this.state.error && (
                    <div className="row py-5">
                        <div className="alert alert-danger m-auto" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                )}

                { this.state.src && !this.state.loading && !this.state.error && (
                    <iframe
                        src={this.state.src}
                        title={this.state.title}
                        width={this.state.width}
                        height={this.state.height}
                        frameBorder={this.state.frameborder}
                        style={this.state.style}
                        allowFullScreen={this.state.allowfullscreen}>
                    </iframe>
                )}

            </section>
        );
    }
}

export default ContactMap;

