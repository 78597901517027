import React from "react";
import { Link } from "react-router-dom";
import API from "./../../api";

class HomeClasses extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            data: {
                title1: "",
                title2: "",
                subtitle: ""
            },
            classes: [],
            error: false,
            loading: true,
        };
    }

    render() {
        return (
            <section className="home-classes">
                <div className="effect pt-4 pt-lg-5">
                    <div className="container">
                        { this.state.loading && (
                            <div className="row">
                                <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                            </div>
                        )}

                        { this.state.error && (
                            <div className="row pb-3">
                                <div className="alert alert-danger m-auto" role="alert">
                                    {this.state.error}
                                </div>
                            </div>
                        )}

                        { this.state.classes && this.state.data && !this.state.loading && !this.state.error && (
                            <React.Fragment>
                                <h1><span>{this.state.data.title1}</span>{this.state.data.title2}</h1>
                                <p className="m-0">{this.state.data.subtitle}</p>
                                <div className="row text-center">
                                    {this.state.classes.map((clas, i) => {
                                        return (
                                            <div className="col-lg-3 col-xs-6 pt-3" key={i}>
                                                <Link to={`/classes#class-${i+1}`}>
                                                    <div className="class-card">
                                                        <img src={clas.image_url} alt={`class-${i+1}`} />
                                                        <div className="class-description">
                                                            {clas.name}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="row py-4 py-lg-5">
                                    <Link to="/classes" className="btn btn-outline-light">{this.state.data.text}</Link>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("classLinks")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ data: res.data });
                }
            })
            .catch(err => {});
        API.get("classes")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ classes: res.data.slice(0,4) });
                }
            })
            .catch(err => {
                if(this._isMounted) {
                    this.setState({ error: err.message });
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default HomeClasses;