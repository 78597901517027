import React from "react";
import SideMenu from "react-sidemenu";
import API from "./../../api";

import ClassComponent from "./ClassComponent";

class ClassesList extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: true,
            activeItem : "#main-nav",
            items: [
                { divider: true, label: "Our Classes", value: "#main-nav" }
            ]
        };
        this.handleClick = this.handleClick.bind(this);
        this.checkHash = this.checkHash.bind(this);
    }

    render() {
        let item = this.state.items.find(item => item.value === this.state.activeItem);

        return (
            <section className="classes first">
                <div className="container">

                    { this.state.loading && (
                        <div className="row py-5">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row py-5">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { !this.state.loading && !this.state.error && this.state.items.length === 1 && (
                        <div className="row py-5">
                            <div className="alert alert-light m-auto" role="alert">
                                No classes found
                            </div>
                        </div>
                    )}

                    { this.state.items.length > 1 && (
                        <div className="row">
                            <div className="col-xxl-3 col-lg-4 py-3 pr-lg-0">
                                <SideMenu theme='custom' items={this.state.items} activeItem={this.state.activeItem} onMenuItemClick={this.handleClick}/>
                            </div>
                            <div className="col-xxl-9 col-lg-8 py-3 shade-left">
                                <ClassComponent title={item.data.name} subtitle={item.data.subtitle} content={item.data.content} image={item.data.image_url} />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }

    checkHash() {
        let hash = window.location.hash;
        if (this.state.items.find(item => item.value === hash)){
            return hash;
        }
        else {
            window.location.hash = "";
            return undefined;
        }
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("classes")
            .then(res => {
                if(this._isMounted) {
                    let items = [{divider: true, label: "Our Classes", value: "#main-nav" }];
                    res.data.forEach((item, i) => {
                        items.push({
                            label: item.name,
                            value: `#class-${i+1}`,
                            data: item
                        });
                    });
                    this.setState({ items, activeItem: items[1].value || this.checkHash()  });
                    this.setState({ activeItem: this.checkHash() || items[1].value });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                    let items = [{divider: true, label: "Our Classes", value: "#main-nav" }];
                    this.setState({ items, activeItem: "#main-nav" });
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClick(value) {
        this.setState({ activeItem: value });
        window.location.hash = value;
    }
}

export default ClassesList;