import React from "react";

import ContactForm from "./../components/Contact/ContactForm";
import Classes from "./../components/Classes";
import ContactMap from "./../components/Contact/ContactMap";

class Contact extends React.Component {
    render() {
        return (
            <div className="page">
                <ContactForm />
                <Classes />
                <ContactMap />
            </div>
        );
    }
}

export default Contact;