import React from "react";

import TeamList from "./../components/Team/TeamList";
import Classes from "./../components/Classes";
import Gallery from "./../components/Gallery";

class Team extends React.Component {
    render() {
        return (
            <div className="page">
                <TeamList/>
                <Classes/>
                <Gallery/>
            </div>
        );
    }
}

export default Team;