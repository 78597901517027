import React from "react";

import EventsList from "./../components/Events/EventsList";
import Classes from "./../components/Classes";

class Events extends React.Component {
    render() {
        return (
            <div className="page">
                <EventsList/>
                <Classes/>
            </div>
        );
    }
}

export default Events;