import React from "react";
import parse from "html-react-parser";
import API from "./../../api";

import { ReactComponent as Shield } from "./../../assets/img/shield-grey.svg";
import { ReactComponent as Logo } from "./../../assets/img/logo-small.svg";
import swords from "./../../assets/img/swords.png";

class HomeAbout extends React.Component {
    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            home: {
                title: "title",
                subtitle: "subtitle",
                content: "content"
            },
            error: false,
            loading: true
        };
    }
    render() {
        return (
            <section className="home-about">
                <div className="wraper">
                    <Shield className="shield"/>
                    <Logo className="logo"/>
                </div>
                <div className="content">

                    { this.state.loading && (
                        <div className="row py-5">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row py-5">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { !this.state.loading && !this.state.error && (
                        <div>
                            <div className="container pt-5 pb-3 pb-md-5">
                                <h1>{this.state.home.title}</h1>
                                <h3>{this.state.home.subtitle}</h3>
                            </div>
                            <div className="container pb-3 pb-md-5">
                                <div className="row">
                                    <div className="col-md-3 d-none d-md-block">
                                        <img src={swords} alt={"swords"}/>
                                        <div className="line-wrap"></div>
                                    </div>
                                    <div className="col-md-9">
                                        {parse(this.state.home.content)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
    componentDidMount() {
        this._isMounted = true;

        API.get("home")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ home: res.data });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default HomeAbout;