import React from "react";
import { Link } from "react-router-dom";
import API from "./../../api";

class HomeHeader extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            header: {
                title1: '', 
                title2: '',
                button_text: ''
            }
        };
    }

    render() {
        return (
            <header id="header" className="d-flex align-items-center">
                <div className="container">
                    <div className="row yellow">{this.state.header.title1}</div>
                    <div className="row">{this.state.header.title2}</div>
                    <div className="row pt-4 pt-md-5">
                        <Link to="/classes" className="btn btn-outline-light">{this.state.header.button_text}</Link>
                    </div>
                </div>
            </header>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("classHeader")
        .then(res => {
            if(this._isMounted) {
                this.setState({ header: res.data });
            }
        })
        .catch(err => {});

        this.handleResize();
        this.image = document.getElementById("header-img");
        this.image.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        this.image.removeEventListener("load", this.handleResize);
        window.removeEventListener("resize", this.handleResize);
        
        this._isMounted = false;
    }

    handleResize() {
        let bgh = document.getElementById("header-img").clientHeight;
        document.getElementById("header").style.height = (bgh-10) + "px";
    }
}

export default HomeHeader;