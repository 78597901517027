import React from "react";
import { Link, NavLink } from "react-router-dom";

// images
import { ReactComponent as Logo } from "./../assets/img/logo.svg";
import { ReactComponent as Facebook } from "./../assets/img/facebook-logo.svg";

class Nav extends React.Component
{
    render() {
        return(
            <section>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark scall-nav" id="scall-navbar">
                    <div className="container">
                        <Link className="navbar-brand d-flex align-items-center justify-content-center" to="/">
                            <Logo className="navbar-image"/>
                        </Link>
                        <button id="navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#scall-nav" aria-controls="scall-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="container">
                            <div className="row">
                                <div className="ml-auto nav-social">Follow us on:
                                    <a className="nav-facebook" href={this.props.facebook} target="_blank" rel="noopener noreferrer">
                                        <Facebook />
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="collapse navbar-collapse" id="scall-nav">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <NavLink exact to="/" onClick={this.colapse} className="nav-link" activeClassName="active" >
												Home
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/team" onClick={this.colapse} className="nav-link" activeClassName="active">Team</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/classes" onClick={this.colapse} className="nav-link" activeClassName="active">Classes</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/events" onClick={this.colapse} className="nav-link" activeClassName="active">Events</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about" onClick={this.colapse} className="nav-link" activeClassName="active">About</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact" onClick={this.colapse} className="nav-link" activeClassName="active">Contact</NavLink>
                                        </li>
                                    </ul>
                                    <div className="ml-auto collapse-social pt-3">Follow us on:
                                        <a className="nav-facebook" href="https://www.facebook.com/groups/Scallagrims/" target="_blank" rel="noopener noreferrer">
                                            <Facebook />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </nav>

                <div className="scall-background">
                    <img id="header-img" src={this.props.background} alt={"background"}></img>
                    <div className="shade"></div>
                </div>

            </section>
        );
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    colapse() {
        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const navbar = document.getElementById("navbar-toggler");
        if(w < 992) {
            navbar.click();
        }
    }

    handleScroll() {
        const doc = document.documentElement;
        const st = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

        let navbar = document.getElementById("scall-navbar");

        if (st > 30) {
            if( !navbar.classList.contains("scrolled") ) {
                navbar.classList.add("scrolled");
            }
            if( !navbar.classList.contains("awake") ) {
                navbar.classList.add("awake");
            }
        }
        else {
            if( navbar.classList.contains("scrolled") ) {
                navbar.classList.remove("scrolled");
            }
            if ( navbar.classList.contains("awake") ) {
                navbar.classList.remove("awake");
            }
        }
    }
}

export default Nav;