import React, { Fragment } from "react";
import { NavLink } from 'react-router-dom';
import API from './../api';
class NotFound extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            data: {}
        };
    }

    render() {
        return (
            <div className="not-found">
                <div>
                    { this.state.loading && (
                        <div className="row py-5">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row py-5">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { !this.state.loading && !this.state.error && (
                        <Fragment>
                            <div className="text">
                                <img src={this.state.data.image} alt="404"/>
                            </div>
                            <div className="message">
                                {this.state.data.title}
                            </div>
                            <div className="button">
                                <NavLink exact to="/" className="btn btn-light">
                                    {this.state.data.button_text}
                                </NavLink>
                            </div>
                        </Fragment>
                    )}
                    
                </div>
            </div>
        );
    }
    componentDidMount() {
        this._isMounted = true;

        API.get("errorPage")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ data: res.data });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default NotFound;