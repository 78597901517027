import React from "react";
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from "react-images";
import API from "./../api";

class Galery extends React.Component {

    _isMounted = false;
    settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 800,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 667,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    constructor(props){
        super(props);

        this.state = {
            images: [],
            info: {
                title: "",
                subtitle: ""
            },
            error: false,
            loading: true,
            modalIsOpen: false,
            selectedIndex: 0
        };
        this.toggleLightbox = this.toggleLightbox.bind(this);
    }
    toggleLightbox (selectedIndex) {
        if(!this.state.lightboxIsOpen) {
            document.body.classList.add("modal-open");
            document.body.scroll = "no";
        }
        else {
            document.body.classList.remove("modal-open");
            document.body.scroll = "yes";
        }

        this.setState({
            lightboxIsOpen: !this.state.lightboxIsOpen,
            selectedIndex
        });
    }
    render() {
        return (
            <section className="gallery">
                <div className="container pt-4 pb-5">

                    { this.state.loading && (
                        <div className="row">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { this.state.images.length === 0 && !this.state.loading && !this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-light m-auto" role="alert">
                                No gallery items
                            </div>
                        </div>
                    )}

                    { this.state.images.length > 0 && (
                        <React.Fragment>
                            <h1>{this.state.info.title}</h1>
                            <p>{this.state.info.subtitle}</p>

                            <div className="container pb-5">
                                <Slider {...this.settings}>
                                    { this.state.images.map((image, i) => {
                                        return (
                                            <div className="carousel-item" key={i}>
                                                <img src={image.source} alt={image.author} className="carousel-image" onClick={()=> this.toggleLightbox(i)}/>
                                            </div>
                                        );
                                    })}
                                </Slider>
                                <ModalGateway>
                                    {this.state.lightboxIsOpen && !this.state.loading ? (
                                        <Modal onClose={this.toggleLightbox}>
                                            <Carousel
                                                currentIndex={this.state.selectedIndex}
                                                views={this.state.images}
                                            />
                                        </Modal>
                                    ) : null}
                                </ModalGateway>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </section>
        );
    }
    componentDidMount() {
        this._isMounted = true;

        API.get("galleryInfo")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ info: res.data });
                }
            })
            .catch(error => {});

        API.get("gallery")
            .then(res => {
                if(this._isMounted) {
                    let images = [];
                    res.data.forEach(i => {
                        images.push({
                            source: i.image_url,
                            caption: i.image_description,
                            author: i.image_title
                        });
                    });
                    this.setState({ images });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

}

export default Galery;