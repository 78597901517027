import React from "react";
import API from "./../api";

import Nav from "./../components/Nav";
import Footer from "./../components/Footer";
import background from "./../assets/img/background.jpg";

/**
 * Page component is needed to render page layout (header and footer)
 * Also it detects page updates and scroll page to top on any route
 */

class Page extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            facebook: "#",
            header: {}
        };
    }

    render() {
        return (
            <div>
                <Nav facebook={this.state.facebook} background={this.state.header.image ? this.state.header.image : background} />
                {this.props.children}
                <Footer facebook={this.state.facebook} />
            </div>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("facebookUrl")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ facebook: res.data.url });
                }
            })
            .catch(err => {});
        API.get("classHeader")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ header: res.data })
                }
            })
            .catch(err => {});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        this.scrollToTop(500);
        this.updateFooterSpacer();
    }

    scrollToTop(scrollDuration) {
        let scrollHeight = window.scrollY,
            scrollStep = Math.PI / ( scrollDuration / 15 ),
            cosParameter = scrollHeight / 2;

        let scrollCount = 0,
            scrollMargin,
            scrollInterval = setInterval( () => {
                if ( window.scrollY !== 0 ) {
                    scrollCount = scrollCount + 1;
                    scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                    window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                }
                else clearInterval(scrollInterval);
            }, 15 );
    }

    updateFooterSpacer() {
        let spacer = document.getElementsByClassName('footer-spacer');
        if(!spacer[0]) return;
        else spacer = spacer[0];

        let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let top = spacer.getBoundingClientRect().top;
        if(top > h) {
            spacer.style.height = ''
        }
        else {
            spacer.style.height = (h - top) + 'px';
        }
    }
}

export default Page;