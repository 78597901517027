import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import API from "./../../api";

import fb from "./../../assets/img/facebook-blue.png";

class ContactFrom extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            recaptcha: process.env.REACT_APP_RECAPTCHA_KEY,
            facebook: "#",
            form_data: {
                name: "",
                phone: "",
                email: "",
                recaptcha: false,
                message: ""
            },
            errors: [],
            sended: false,
            loading: false,
            contact_data: {
                loading: true,
                error: false,
                data: false
            }
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.recaptchaTrue = this.recaptchaTrue.bind(this);
        this.recaptchaFalse = this.recaptchaFalse.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <section className="contact-form first">
                <div className="container py-3 py-md-4">
                    <h1>Contact us</h1>
                    <div className="wrap-horizontal"></div>
                    <div className="row">
                        <div className="col-lg-3 py-3 px-lg-4">
                            <div className="container-fluid text-left text-lg-right">
                                { this.state.contact_data.loading && (
                                    <div className="row">
                                        <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                )}

                                { this.state.contact_data.error && (
                                    <div className="row pb-3">
                                        <div className="alert alert-danger m-auto text-center" role="alert">
                                            {this.state.contact_data.error}
                                        </div>
                                    </div>
                                )}

                                { this.state.contact_data.data && !this.state.contact_data.error && !this.state.contact_data.loading && (
                                    <React.Fragment>
                                        <h3>Contact info</h3>
                                        <div className="pt-3">
                                            <div className="pb-lg-4 pb-2">
                                                <div className="no-wrap"><b>Phone:</b><div className="no-wrap">{this.state.contact_data.data.phone}</div></div>
                                            </div>
                                            <div className="pb-lg-4 pb-2">
                                                <div className="no-wrap"><b>Address:</b></div>
                                                <div className="no-wrap">{this.state.contact_data.data.address1},</div>
                                                <div className="no-wrap">{this.state.contact_data.data.address2}</div>
                                            </div>
                                        </div>
                                        <a href={this.state.contact_data.data.directions_url} target="_blank" rel="noopener noreferrer" className="btn btn-light">Get directions</a>
                                        <p className="pt-3">
                                            <a href={this.state.facebook} className="px-2" target="_blank" rel="noopener noreferrer">
                                                <img src={fb} alt={"fb"}/>
                                            </a>
                                        </p>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-9 pt-3 px-lg-4">
                            <div className="container-fluid">
                                <div className="wrap-vertical d-none d-lg-block"></div>
                                <h3>Send us a message today</h3>
                                <form onSubmit={this.handleSubmit} className="pt-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={this.handleNameChange} placeholder="Name"/>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={this.handlePhoneChange} placeholder="Phone Number"/>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" onChange={this.handleEmailChange} className="form-control" placeholder="Email Address"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea className="form-control" rows="3" onChange={this.handleMessageChange}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-2 d-flex align-items-center">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <ReCAPTCHA className="contact-recaptcha"
                                                    sitekey={this.state.recaptcha}
                                                    onChange={this.recaptchaTrue}
                                                    onErrored={this.recaptchaFalse}
                                                    onExpired={this.recaptchaFalse}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-light">Submit message</button>
                                            </div>
                                        </div>
                                    </div>
                                    { this.state.loading && (
                                        <div className="row">
                                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    )}
                                    { this.state.errors.length > 0 && (
                                        <div className="row pt-2">
                                            <div className="alert alert-danger px-1" role="alert">
                                                <ul className="my-1">
                                                    { this.state.errors.map((error, i) => {
                                                        return <li key={i}>{error}</li>;
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    { this.state.sended && (
                                        <div className="row pt-2">
                                            <div className="alert alert-success mb-2" role="alert">Your message has been sent successfully!</div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("facebookUrl")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ facebook: res.data.url });
                }
            })
            .catch(err => {});
        API.get("contactInfo")
            .then(res => {
                if(this._isMounted) {
                    let contact_data = this.state.contact_data;
                    contact_data.data = res.data;
                    this.setState({ contact_data });
                }
            })
            .catch(err => {
                if(this._isMounted) {
                    let contact_data = this.state.contact_data;
                    contact_data.error = err.message;
                    this.setState({ contact_data });
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    let contact_data = this.state.contact_data;
                    contact_data.loading = false;
                    this.setState({ contact_data });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleNameChange(e) {
        let form_data = this.state.form_data;
        form_data.name = e.target.value;
        this.setState({ form_data });
    }

    handlePhoneChange(e) {
        let form_data = this.state.form_data;
        form_data.phone = e.target.value;
        this.setState({ form_data });
    }

    handleEmailChange(e) {
        let form_data = this.state.form_data;
        form_data.email = e.target.value;
        this.setState({ form_data });
    }

    handleMessageChange(e) {
        let form_data = this.state.form_data;
        form_data.message = e.target.value;
        this.setState({ form_data });
    }

    recaptchaFalse(e) {
        let form_data = this.state.form_data;
        form_data.recaptcha = false;
        this.setState({ form_data });
    }

    recaptchaTrue(e) {
        let form_data = this.state.form_data;
        form_data.recaptcha = true;
        this.setState({ form_data });
    }

    handleSubmit(e) {
        e.preventDefault();
        let errors = [];
        this.setState({ errors, sended: false, loading: true });
        const phone = /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const email = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        // validate
        if (this.state.form_data.name === "") {
            errors.push("The \"Name\" field is required.");
        }
        if (this.state.form_data.phone === "") {
            errors.push("The \"Phone Number\" field is required.");
        }
        else if (!phone.test(this.state.form_data.phone)) {
            errors.push("Please enter a valid canadian phone number.");
        }
        if (this.state.form_data.email === "") {
            errors.push("The \"Email Address\" field is required.");
        }
        else if (!email.test(this.state.form_data.email)) {
            errors.push("Please enter a valid email address.");
        }
        if (this.state.form_data.message === "") {
            errors.push("The \"Message\" field is required.");
        }
        if (!this.state.form_data.recaptcha) {
            errors.push("Please сonfirm that You are not a robot.");
        }

        // error check
        if(errors.length > 0) {
            this.setState({ errors, loading: false });
            return;
        }

        // send request
        API.post("contact", this.state.form_data)
            .then(response => {
                if(this._isMounted) {
                    this.setState({ sended: true });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            errors.push(error.response.data.message);
                            this.setState({ errors });
                        }
                    }
                    else {
                        errors.push(error.message);
                        this.setState({ errors });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });

    }
}

export default ContactFrom;

