import React from "react";
import API from "./../../api";

import { ReactComponent as Arrow } from "./../../assets/img/arrow.svg";
class EventsList extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            events: [],
            error: false,
            loading: true
        };
    }

    render() {
        return (
            <section className="events first">
                <div className="container py-4 py-md-4">
                    <h1 className="pb-2 py-md-3">Events</h1>

                    { this.state.loading && (
                        <div className="row">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { this.state.events.length === 0 && !this.state.loading && !this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-light m-auto" role="alert">
                                No events found
                            </div>
                        </div>
                    )}

                    { this.state.events.length > 0 && (
                        <div className="row d-flex justify-content-center align-items-center">
                            { this.state.events.map((event, i) => {
                                event.date = new Date(event.start_time);
                                return (
                                    <div className="col-xxl-3 col-lg-4 col-sm-6 py-3" key={i}>
                                        <a href={"https://www.facebook.com/events/" + event.id} target="_blank" rel="noopener noreferrer">
                                            <div className="event-date">
                                                <div className="day">{event.date.getDate()}</div>
                                                <div className="month">{this.getMonthName(event.date.getMonth())}</div>
                                            </div>
                                            <div className="event-image">
                                                <img src={event.cover} alt={event.name}/>
                                            </div>
                                            <div className="event-name">

                                                <span className="name">{event.name}</span>
                                                <span className="details-arrow">
                                                    <Arrow/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </section>
        );
    }

    getMonthName(index) {
        const monthes = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sepr", "Oct", "Nov", "Dec"];
        return monthes[index];
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("events")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ events: res.data });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default EventsList;