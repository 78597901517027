import React from "react";
import parse from "html-react-parser";

class AboutSecond extends React.Component {
    render() {
        return (
            <section className="about pb-3">
                <div className="container py-3 py-lg-5">
                    <div className="row">
                        <div className="col">
                            { this.props.loading && (
                                <div className="row py-5">
                                    <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                                </div>
                            )}

                            { this.props.error && (
                                <div className="row py-5">
                                    <div className="alert alert-danger m-auto" role="alert">
                                        {this.props.error}
                                    </div>
                                </div>
                            )}

                            { !this.props.loading && !this.props.error && (
                                <div>
                                    <img src={this.props.data.image_url} alt={"about2"} className="about-image left" />
                                    {parse(this.props.data.content)}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutSecond;