import React from "react";
import parse from "html-react-parser";

class ClassComponent extends React.Component {
    render() {
        return (
            <div className="container-fluid class-content">
                <h1>{this.props.title}</h1>
                <h3>{this.props.subtitle}</h3>
                <div className="row py-2">
                    <div className="col">
                        <img src={this.props.image} alt={"classimage"} className="class-image"/>
                        {parse(this.props.content)}
                    </div>
                </div>
            </div>
        );
    }
}

export default ClassComponent;