import React from "react";
import API from "./../api";

// images
import { ReactComponent as Shield } from "./../assets/img/shield.svg";
import facebook from "./../assets/img/facebook.png";

class Footer extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: {
                email: "",
                address: ""
            }
        };
    }

    render() {
        return (
            <footer>
                <div className="footer-wraper">
                    <div className="wraper"></div>
                    <Shield className="footer-shield"/>
                </div>
                <div className="container pt-4 pb-3 footer-content">
                    <div className="row">
                        <div className="col-12 footer-facebook">
                            <span>Follow us on:</span>
                            <a href={this.props.facebook} target="_blank" rel="noopener noreferrer"><img src={facebook} alt={"facebook"}/></a>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-lg-6 text-left py-2">
                            Email: <a href={"mailto:" + this.state.data.email}>{this.state.data.email}</a><br/>
                            Address: {this.state.data.address}
                        </div>
                        <div className="col-lg-6 text-lg-right py-2">
							© Scallagrims Medieval Martial Arts Centre.<br/>
                            All rights reserved.
                        </div>
                    </div>
                </div>
                <div className="footer-spacer"></div>
            </footer>
        );
    }

    componentDidMount() {
        this._isMounted = true;

        API.get("footer")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ data: res.data });
                }
            })
            .catch(err => {});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default Footer;