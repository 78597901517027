import React from "react";

import ClassesList from "./../components/Classes/ClassesList";
import Galery from "./../components/Gallery";

class Classes extends React.Component {
    render() {
        return (
            <div className="page">
                <ClassesList />
                <Galery />
            </div>
        );
    }
}

export default Classes;