import React from "react";

import HomeHeader from "./../components/Home/HomeHeader";
import HomeAbout from "./../components/Home/HomeAbout";
import HomeClasses from "./../components/Home/HomeClasses";
import Gallery from "./../components/Gallery";

class Home extends React.Component {
    render() {
        return (
            <div className="page">
                <HomeHeader />
                <HomeAbout />
                <HomeClasses />
                <Gallery />
            </div>
        );
    }
}

export default Home;