import React from "react";
import API from "./../api";

import AboutFirst from "./../components/About/AboutFirst";
import Classes from "./../components/Classes";
import AboutSecond from "./../components/About/AboutSecond";

class About extends React.Component {
    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            about: {
                section_first: {
                    title: "title",
                    subtitle: "subtitle",
                    content: "content"
                },
                section_second: {
                    content: "content"
                }
            },
            error: false,
            loading: true
        };
    }
    render() {
        return (
            <div className="page">
                <AboutFirst data={this.state.about.section_first} error={this.state.error} loading={this.state.loading}/>
                <Classes />
                <AboutSecond data={this.state.about.section_second} error={this.state.error} loading={this.state.loading}/>
            </div>
        );
    }
    componentDidMount() {
        this._isMounted = true;

        API.get("about")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ about: res.data });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default About;