import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Page from "./pages/Page";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Events from "./pages/Events";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Classes from "./pages/Classes";

class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>

                    <Route exact path="/">
                        <Page>
                            <Home/>
                        </Page>
                    </Route>

                    <Route exact path="/team">
                        <Page>
                            <Team/>
                        </Page>
                    </Route>

                    <Route exact path="/classes">
                        <Page>
                            <Classes/>
                        </Page>
                    </Route>

                    <Route exact path="/events">
                        <Page>
                            <Events/>
                        </Page>
                    </Route>

                    <Route exact path="/about">
                        <Page>
                            <About/>
                        </Page>
                    </Route>

                    <Route exact path="/contact">
                        <Page>
                            <Contact/>
                        </Page>
                    </Route>

                    <Route component={NotFound} status={404}/>

                </Switch>
            </Router>
        );
    }
}

export default App;