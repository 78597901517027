import React from "react";
import parse from "html-react-parser";
import API from "./../../api";

import { ReactComponent as Arrow } from "./../../assets/img/arrow.svg";
class TeamList extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            data: {
                title: "",
                subtitle: ""
            },
            team: [],
            error: false,
            loading: true
        };
    }

    render() {
        return (
            <section className="team first">
                <div className="container py-4">

                    { this.state.loading && (
                        <div className="row">
                            <div className="lds-ellipsis m-auto"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )}

                    { this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-danger m-auto" role="alert">
                                {this.state.error}
                            </div>
                        </div>
                    )}

                    { this.state.team.length === 0 && !this.state.loading && !this.state.error && (
                        <div className="row pb-3">
                            <div className="alert alert-light m-auto" role="alert">
                                No members found
                            </div>
                        </div>
                    )}

                    { this.state.team.length > 0 && (
                        <div>
                            <h1>{this.state.data.title}</h1>
                            <div className="wrap-horizontal"></div>
                            <p>{this.state.data.subtitle}</p>
                            <div className="row d-flex justify-content-center align-items-center px-2">
                                { this.state.team.map((member, i) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 col-xxs-12 p-3 p-lg-4" key={i} data-toggle="modal" data-target={"#member-"+i+"-details"}>
                                            <div className="member">
                                                <img src={member.image_url} alt={member.full_name}  className="member-photo"/>
                                                <div className="member-name">
                                                    <span>{member.full_name}</span>
                                                    <span className="details-arrow">
                                                        <Arrow/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="modal fade" id={"member-"+i+"-details"} tabIndex="-1" role="dialog" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-body">
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <div className="container-fluid p-3">
                                                                <div className="row d-flex justify-content-left align-items-center m-0 mt-md-1 p-0 mt-3">
                                                                    <div className="col-md-5 pb-2">
                                                                        <img src={member.image_url} alt={member.full_name}  className="member-photo"/>
                                                                    </div>
                                                                    <div className="col-md-7 member-desc">
                                                                        <h5>{member.full_name}</h5>
                                                                        <React.Fragment>{parse(member.description)}</React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }

    componentDidMount() {
        this._isMounted = true;
        API.get("teamTopic")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ data: res.data });
                }
            })
            .catch(error => {});
        API.get("team")
            .then(res => {
                if(this._isMounted) {
                    this.setState({ team: res.data });
                }
            })
            .catch(error => {
                if(this._isMounted) {
                    if(error.response) {
                        if(error.response.data.message) {
                            this.setState({ error: error.response.data.message });
                        }
                    }
                    else {
                        this.setState({ error: error.message });
                    }
                }
            })
            .finally(() => {
                if(this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default TeamList;